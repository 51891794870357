import React, { FunctionComponent, ReactNode } from 'react'
import { Box } from '@wh-components/core/Box/Box'

interface ContentAreaProps {
    topContent: ReactNode
    bottomContentWithoutPadding?: ReactNode
    children?: undefined
}

// in the SiteMutationLayout, there is no padding included, so for the content below that should not have transparent backgrounds, we need this padding and a white background color
export const ContentArea: FunctionComponent<ContentAreaProps> = (props) => {
    return (
        <Box backgroundColor="semantic.surface">
            <Box marginTop={{ phone: 'm', tablet: '0' }} paddingHorizontal="m" paddingBottom={props.bottomContentWithoutPadding ? 0 : 'm'}>
                {props.topContent}
            </Box>
            {props.bottomContentWithoutPadding && (
                <Box paddingBottom={{ phone: 'm', tablet: 'l' }}>{props.bottomContentWithoutPadding}</Box>
            )}
        </Box>
    )
}
