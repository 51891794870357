import { SEOHeading } from '@bbx/search-journey/common/components/SEOHeading/SEOHeading'
import bapStartBackgroundX1 from '@bbx/static_hashed//img/bap/startpage/bap_startpage_L.png'
import bapStartBackgroundX2 from '@bbx/static_hashed//img/bap/startpage/bap_startpage_L@2x.png'
import { Box } from '@wh-components/core/Box/Box'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { SearchEntryAreaBottomIFrameType } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingPageModificationsState'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import React, { Fragment, FunctionComponent, ReactNode, useContext } from 'react'
import { css, useTheme } from 'styled-components'

interface SearchEntryAreaProps {
    searchBox: ReactNode
    categoryBox: ReactNode
    nrOfAdverts: number | undefined
    pageTitle: string
}

export const SearchEntryArea: FunctionComponent<SearchEntryAreaProps> = ({ searchBox, categoryBox, pageTitle, nrOfAdverts }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const titleColor = advertisingState.pageModifications.foregroundColors?.['bap-title']
    const titleCounterColor = advertisingState.pageModifications.foregroundColors?.['bap-title-counter']
    const searchEntryAreaBackgroundTransparent =
        advertisingState.pageModifications.backgroundTransparent?.includes('bap-search-entry-area') ?? false
    const searchEntryAreaBackgroundColor = searchEntryAreaBackgroundTransparent
        ? 'transparent'
        : (advertisingState.pageModifications.backgroundColors?.['bap-search-entry-area'] ?? theme.colors.semantic.surface.DEFAULT)
    const searchBoxParentContainerBackgroundTransparent =
        advertisingState.pageModifications.backgroundTransparent?.includes('bap-search-box-parent-container') ?? false
    const searchBoxParentContainerBackgroundColor = advertisingState.pageModifications.backgroundColors?.['bap-search-box-parent-container']
    const searchBoxParentContainerBorderColor = advertisingState.pageModifications.borders?.['bap-search-box-parent-container']

    const couldSearchBoxParentContainerBackgroundBeTransparent =
        searchBoxParentContainerBackgroundTransparent || typeof searchBoxParentContainerBackgroundColor !== 'undefined'
    // cannot show rounded border when the bap-search-box-parent-container could be transparent, because the "hole" in the css is implemented by just coloring all the areas around it, which makes problems with areas left by rounded borders
    const canShowSearchBoxParentContainerBottomRoundedBorder = !couldSearchBoxParentContainerBackgroundBeTransparent
    const bapSearchEntryAreaBottomIFrame = advertisingState.pageModifications.bapSearchEntryAreaBottomIFrame

    const bapSearchBoxRightIFrame = advertisingState.pageModifications.bapSearchBoxRightIFrame
    const shouldRenderbapSearchBoxRightIFrameBasedOnBreakpoint = useResponsiveValue(
        {
            phone: false,
            tablet: true,
        },
        false,
    )

    const bapSmallLeaderboardIFrame = advertisingState.pageModifications.bapSmallLeaderboardIFrame
    const shouldRenderBapSmallLeaderboardIFrameBasedOnBreakpoint = useResponsiveValue(
        {
            phone: true,
            tablet: false,
        },
        false,
    )

    return (
        <Fragment>
            {/* // make the background color on phone always white, we do not want the site mutations to being able to make the page background visible */}
            <Box
                padding={{ tablet: 'm' }}
                // needs to be same spread as padding
                boxShadow={{
                    phone: undefined,
                    tablet: `inset 0 0 0 ${theme.space.m}px ${searchEntryAreaBackgroundColor}`,
                }}
                backgroundColor={{ phone: 'semantic.surface', tablet: 'transparent' }}
            >
                {shouldRenderBapSmallLeaderboardIFrameBasedOnBreakpoint && typeof bapSmallLeaderboardIFrame !== 'undefined' && (
                    <Box display={{ phone: 'block', tablet: 'none' }} height={125}>
                        <iframe
                            src={bapSmallLeaderboardIFrame.src}
                            frameBorder="0"
                            title="Werbung"
                            aria-hidden="true"
                            css={css`
                                display: block;
                                width: 100%;
                                height: 100%;

                                ${(p) => p.theme.media.print} {
                                    display: none;
                                }
                            `}
                        />
                    </Box>
                )}
                <Box paddingBottom="s" display={{ phone: 'none', tablet: 'block' }} backgroundColor={searchEntryAreaBackgroundColor}>
                    <SEOHeading
                        nrOfAdverts={nrOfAdverts}
                        titleColor={titleColor}
                        titleCounterColor={titleCounterColor}
                        pageTitle={pageTitle}
                    />
                </Box>
                <Box width="100%">
                    <Box
                        backgroundColor={
                            couldSearchBoxParentContainerBackgroundBeTransparent ? 'transparent' : searchEntryAreaBackgroundColor
                        }
                    >
                        <Box
                            width="100%"
                            border={searchBoxParentContainerBorderColor ? `1px solid ${searchBoxParentContainerBorderColor}` : undefined}
                            borderRadius={{
                                phone: undefined,
                                tablet: canShowSearchBoxParentContainerBottomRoundedBorder ? `${theme.borderRadius}px` : '0 0 0 0',
                            }}
                            background={{
                                phone: searchBoxParentContainerBackgroundTransparent
                                    ? 'transparent'
                                    : typeof searchBoxParentContainerBackgroundColor === 'undefined'
                                      ? `${theme.colors.palette.primary.main}`
                                      : searchBoxParentContainerBackgroundColor,
                                tablet: searchBoxParentContainerBackgroundTransparent
                                    ? 'transparent'
                                    : typeof searchBoxParentContainerBackgroundColor === 'undefined'
                                      ? `${theme.colors.palette.primary.main} image-set(url('${bapStartBackgroundX1}') 1x, url('${bapStartBackgroundX2}') 2x) no-repeat right`
                                      : searchBoxParentContainerBackgroundColor,
                            }}
                            backgroundSize={{ phone: undefined, tablet: 'cover', desktop: 'auto, cover' }}
                        >
                            <BapSearchBoxRowContainer
                                left={searchBox}
                                right={
                                    shouldRenderbapSearchBoxRightIFrameBasedOnBreakpoint &&
                                    typeof bapSearchBoxRightIFrame !== 'undefined' ? (
                                        <iframe
                                            src={bapSearchBoxRightIFrame.src}
                                            frameBorder="0"
                                            title="Werbung"
                                            aria-hidden="true"
                                            css={css`
                                                display: block;
                                                width: 100%;
                                                height: 100%;

                                                ${(p) => p.theme.media.print} {
                                                    display: none;
                                                }
                                            `}
                                        />
                                    ) : (
                                        <Box width={300} />
                                    )
                                }
                            />
                        </Box>
                        {categoryBox}
                    </Box>
                </Box>
                {typeof bapSearchEntryAreaBottomIFrame !== 'undefined' && (
                    <Box paddingTop="m" backgroundColor={searchEntryAreaBackgroundColor}>
                        <BapSearchEntryAreaBottomIFrameContainer config={bapSearchEntryAreaBottomIFrame} />
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}

const BapSearchEntryAreaBottomIFrameContainer: FunctionComponent<{
    config: NonNullable<SearchEntryAreaBottomIFrameType>
}> = (props) => {
    const shouldRenderBapSearchEntryAreaBottomRightIFrameBasedOnBreakpoint = useResponsiveValue(
        {
            phone: false,
            tablet: true,
        },
        false,
    )

    switch (props.config.type) {
        case 'one':
            return (
                <Box width="100%" height={props.config.height}>
                    <iframe
                        src={props.config.src}
                        frameBorder="0"
                        title="Werbung"
                        aria-hidden="true"
                        css={css`
                            display: block;
                            width: 100%;
                            height: 100%;

                            ${(p) => p.theme.media.print} {
                                display: none;
                            }
                        `}
                    />
                </Box>
            )
        case 'two': {
            const left = props.config.left
            const right = props.config.right
            return (
                <BapSearchBoxRowContainer
                    left={
                        left && (
                            <iframe
                                src={left.src}
                                frameBorder="0"
                                title="Werbung"
                                aria-hidden="true"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    height: ${left.height}px;

                                    ${(p) => p.theme.media.print} {
                                        display: none;
                                    }
                                `}
                            />
                        )
                    }
                    right={
                        right &&
                        shouldRenderBapSearchEntryAreaBottomRightIFrameBasedOnBreakpoint && (
                            <iframe
                                src={right.src}
                                frameBorder="0"
                                title="Werbung"
                                aria-hidden="true"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    height: ${right.height}px;
                                    overflow: hidden;

                                    ${(p) => p.theme.media.print} {
                                        display: none;
                                    }
                                `}
                            />
                        )
                    }
                />
            )
        }
    }
}

export const BapSearchBoxRowContainer: FunctionComponent<{ left: ReactNode; right: ReactNode }> = (props) => (
    <Box width="100%" height="100%" display="flex" justifyContent="space-between">
        <Box width={{ phone: '100%', tablet: 'auto' }} marginRight={{ phone: '0', tablet: 'm' }} flex={{ phone: '1 1 auto', tablet: '1' }}>
            {props.left}
        </Box>
        <Box display={{ phone: 'none', tablet: 'block' }}>{props.right}</Box>
    </Box>
)
